/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduledEvent
 */
export interface ScheduledEvent {
    /**
     * 
     * @type {number}
     * @memberof ScheduledEvent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    eventCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    ambassadorUserId?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduledEvent
     */
    vervotechPropertyIds?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledEvent
     */
    checkinDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledEvent
     */
    checkoutDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    checkinStr?: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledEvent
     */
    checkoutStr?: string;
}

/**
 * Check if a given object implements the ScheduledEvent interface.
 */
export function instanceOfScheduledEvent(value: object): value is ScheduledEvent {
    return true;
}

export function ScheduledEventFromJSON(json: any): ScheduledEvent {
    return ScheduledEventFromJSONTyped(json, false);
}

export function ScheduledEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'eventCode': json['event_code'] == null ? undefined : json['event_code'],
        'ambassadorUserId': json['ambassador_user_id'] == null ? undefined : json['ambassador_user_id'],
        'title': json['title'] == null ? undefined : json['title'],
        'description': json['description'] == null ? undefined : json['description'],
        'imageUrl': json['image_url'] == null ? undefined : json['image_url'],
        'vervotechPropertyIds': json['vervotech_property_ids'] == null ? undefined : json['vervotech_property_ids'],
        'checkinDate': json['checkin_date'] == null ? undefined : (new Date(json['checkin_date'])),
        'checkoutDate': json['checkout_date'] == null ? undefined : (new Date(json['checkout_date'])),
        'checkinStr': json['checkin_str'] == null ? undefined : json['checkin_str'],
        'checkoutStr': json['checkout_str'] == null ? undefined : json['checkout_str'],
    };
}

export function ScheduledEventToJSON(value?: ScheduledEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'event_code': value['eventCode'],
        'ambassador_user_id': value['ambassadorUserId'],
        'title': value['title'],
        'description': value['description'],
        'image_url': value['imageUrl'],
        'vervotech_property_ids': value['vervotechPropertyIds'],
        'checkin_date': value['checkinDate'] == null ? undefined : ((value['checkinDate']).toISOString().substring(0,10)),
        'checkout_date': value['checkoutDate'] == null ? undefined : ((value['checkoutDate']).toISOString().substring(0,10)),
        'checkin_str': value['checkinStr'],
        'checkout_str': value['checkoutStr'],
    };
}

