/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelImage } from './HotelImage';
import {
    HotelImageFromJSON,
    HotelImageFromJSONTyped,
    HotelImageToJSON,
} from './HotelImage';
import type { PointsAmount } from './PointsAmount';
import {
    PointsAmountFromJSON,
    PointsAmountFromJSONTyped,
    PointsAmountToJSON,
} from './PointsAmount';
import type { RatingForCategory } from './RatingForCategory';
import {
    RatingForCategoryFromJSON,
    RatingForCategoryFromJSONTyped,
    RatingForCategoryToJSON,
} from './RatingForCategory';
import type { Coordinates } from './Coordinates';
import {
    CoordinatesFromJSON,
    CoordinatesFromJSONTyped,
    CoordinatesToJSON,
} from './Coordinates';
import type { HotelTheme } from './HotelTheme';
import {
    HotelThemeFromJSON,
    HotelThemeFromJSONTyped,
    HotelThemeToJSON,
} from './HotelTheme';
import type { RecommendationSummary } from './RecommendationSummary';
import {
    RecommendationSummaryFromJSON,
    RecommendationSummaryFromJSONTyped,
    RecommendationSummaryToJSON,
} from './RecommendationSummary';
import type { RoomInfo } from './RoomInfo';
import {
    RoomInfoFromJSON,
    RoomInfoFromJSONTyped,
    RoomInfoToJSON,
} from './RoomInfo';
import type { HotelAmenity } from './HotelAmenity';
import {
    HotelAmenityFromJSON,
    HotelAmenityFromJSONTyped,
    HotelAmenityToJSON,
} from './HotelAmenity';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';
import type { ExternalReview } from './ExternalReview';
import {
    ExternalReviewFromJSON,
    ExternalReviewFromJSONTyped,
    ExternalReviewToJSON,
} from './ExternalReview';
import type { TransferPartnerSummary } from './TransferPartnerSummary';
import {
    TransferPartnerSummaryFromJSON,
    TransferPartnerSummaryFromJSONTyped,
    TransferPartnerSummaryToJSON,
} from './TransferPartnerSummary';

/**
 * 
 * @export
 * @interface HotelSummary
 */
export interface HotelSummary {
    /**
     * 
     * @type {RecommendationSummary}
     * @memberof HotelSummary
     */
    recommendationSummary?: RecommendationSummary;
    /**
     * 
     * @type {number}
     * @memberof HotelSummary
     */
    vervotechPropertyId?: number;
    /**
     * 
     * @type {number}
     * @memberof HotelSummary
     */
    providerPropertyId?: number;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    propertyName?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    propertyAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    cityName?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    chainName?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    brandName?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    supplierId?: string;
    /**
     * 
     * @type {Coordinates}
     * @memberof HotelSummary
     */
    coordinates?: Coordinates;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof HotelSummary
     */
    yearBuilt?: number;
    /**
     * 
     * @type {number}
     * @memberof HotelSummary
     */
    yearLastRenovated?: number;
    /**
     * Number of stars
     * @type {number}
     * @memberof HotelSummary
     */
    stars?: number;
    /**
     * Average guest rating
     * @type {number}
     * @memberof HotelSummary
     */
    averageGuestRating?: number;
    /**
     * The number of reviews for the property
     * @type {number}
     * @memberof HotelSummary
     */
    reviewCount?: number;
    /**
     * Average ratings across various review categories, e.g. cleanliness, service, etc
     * @type {Array<RatingForCategory>}
     * @memberof HotelSummary
     */
    categoryRatings?: Array<RatingForCategory>;
    /**
     * URL of hero image
     * @type {string}
     * @memberof HotelSummary
     */
    heroImage?: string;
    /**
     * Name of loyalty program
     * @type {string}
     * @memberof HotelSummary
     */
    loyaltyProgram?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    checkinTime?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    checkoutTime?: string;
    /**
     * 
     * @type {Array<HotelAmenity>}
     * @memberof HotelSummary
     */
    amenities?: Array<HotelAmenity>;
    /**
     * 
     * @type {Array<HotelAmenity>}
     * @memberof HotelSummary
     */
    fullAmenities?: Array<HotelAmenity>;
    /**
     * 
     * @type {Array<HotelImage>}
     * @memberof HotelSummary
     */
    images?: Array<HotelImage>;
    /**
     * 
     * @type {Array<ExternalReview>}
     * @memberof HotelSummary
     */
    externalReviews?: Array<ExternalReview>;
    /**
     * 
     * @type {Array<HotelTheme>}
     * @memberof HotelSummary
     */
    hotelThemes?: Array<HotelTheme>;
    /**
     * 
     * @type {Array<RoomInfo>}
     * @memberof HotelSummary
     */
    rooms?: Array<RoomInfo>;
    /**
     * 
     * @type {Date}
     * @memberof HotelSummary
     */
    checkin?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HotelSummary
     */
    checkout?: Date;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    checkinStr?: string;
    /**
     * 
     * @type {string}
     * @memberof HotelSummary
     */
    checkoutStr?: string;
    /**
     * 
     * @type {number}
     * @memberof HotelSummary
     */
    nights?: number;
    /**
     * Lowest total room rate
     * @type {number}
     * @memberof HotelSummary
     */
    lowestRate?: number;
    /**
     * Currency of lowest rate and nightly rate
     * @type {string}
     * @memberof HotelSummary
     */
    lowestRateCurrency?: string;
    /**
     * Average nightly rate for the lowest rate
     * @type {number}
     * @memberof HotelSummary
     */
    nightlyRate?: number;
    /**
     * Lowest points for any room at hotel for the search range
     * @type {number}
     * @memberof HotelSummary
     */
    lowestPoints?: number;
    /**
     * Average nightly points for the lowest points rate
     * @type {number}
     * @memberof HotelSummary
     */
    lowestNightlyPoints?: number;
    /**
     * 
     * @type {PointsAmount}
     * @memberof HotelSummary
     */
    lowestPointsAmount?: PointsAmount;
    /**
     * Distance from search location in m
     * @type {number}
     * @memberof HotelSummary
     */
    proximity?: number;
    /**
     * The percentile of the points deal
     * @type {number}
     * @memberof HotelSummary
     */
    pointsPercentile?: number;
    /**
     * The number of cents per pt
     * @type {number}
     * @memberof HotelSummary
     */
    pointRedemptionValue?: number;
    /**
     * The percentile of the cash deal
     * @type {number}
     * @memberof HotelSummary
     */
    cashPercentile?: number;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof HotelSummary
     */
    cashDealScore?: number;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof HotelSummary
     */
    pointsDealScore?: number;
    /**
     * The number of free nights for the points deal
     * @type {number}
     * @memberof HotelSummary
     */
    nightsFree?: number;
    /**
     * Transfer partners that it is worth using for this deal
     * @type {Array<TransferPartnerSummary>}
     * @memberof HotelSummary
     */
    transferPartnerDeals?: Array<TransferPartnerSummary>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof HotelSummary
     */
    tags?: Array<Tag>;
    /**
     * User's status in the loyalty program. If None, the user is not a member of the program
     * @type {string}
     * @memberof HotelSummary
     */
    userLoyaltyProgramStatus?: string;
    /**
     * Number of loyalty points the user has with the associated program
     * @type {number}
     * @memberof HotelSummary
     */
    userLoyaltyPoints?: number;
    /**
     * Index of the user's tier in the loyalty program. If None, the user is not a member of the program
     * @type {number}
     * @memberof HotelSummary
     */
    programTierIndex?: number;
    /**
     * If this hotel is in the top rankings for the search
     * @type {boolean}
     * @memberof HotelSummary
     */
    gondolaRecommended?: boolean;
    /**
     * 
     * @type {PointsAmount}
     * @memberof HotelSummary
     */
    earningsPoints?: PointsAmount;
    /**
     * The number of nights earned for a given stay
     * @type {number}
     * @memberof HotelSummary
     */
    statusNights?: number;
}

/**
 * Check if a given object implements the HotelSummary interface.
 */
export function instanceOfHotelSummary(value: object): value is HotelSummary {
    return true;
}

export function HotelSummaryFromJSON(json: any): HotelSummary {
    return HotelSummaryFromJSONTyped(json, false);
}

export function HotelSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'recommendationSummary': json['recommendation_summary'] == null ? undefined : RecommendationSummaryFromJSON(json['recommendation_summary']),
        'vervotechPropertyId': json['vervotech_property_id'] == null ? undefined : json['vervotech_property_id'],
        'providerPropertyId': json['provider_property_id'] == null ? undefined : json['provider_property_id'],
        'propertyName': json['property_name'] == null ? undefined : json['property_name'],
        'propertyAddress': json['property_address'] == null ? undefined : json['property_address'],
        'cityName': json['city_name'] == null ? undefined : json['city_name'],
        'countryCode': json['country_code'] == null ? undefined : json['country_code'],
        'chainName': json['chain_name'] == null ? undefined : json['chain_name'],
        'brandName': json['brand_name'] == null ? undefined : json['brand_name'],
        'supplierId': json['supplier_id'] == null ? undefined : json['supplier_id'],
        'coordinates': json['coordinates'] == null ? undefined : CoordinatesFromJSON(json['coordinates']),
        'description': json['description'] == null ? undefined : json['description'],
        'yearBuilt': json['year_built'] == null ? undefined : json['year_built'],
        'yearLastRenovated': json['year_last_renovated'] == null ? undefined : json['year_last_renovated'],
        'stars': json['stars'] == null ? undefined : json['stars'],
        'averageGuestRating': json['average_guest_rating'] == null ? undefined : json['average_guest_rating'],
        'reviewCount': json['review_count'] == null ? undefined : json['review_count'],
        'categoryRatings': json['category_ratings'] == null ? undefined : ((json['category_ratings'] as Array<any>).map(RatingForCategoryFromJSON)),
        'heroImage': json['hero_image'] == null ? undefined : json['hero_image'],
        'loyaltyProgram': json['loyalty_program'] == null ? undefined : json['loyalty_program'],
        'checkinTime': json['checkin_time'] == null ? undefined : json['checkin_time'],
        'checkoutTime': json['checkout_time'] == null ? undefined : json['checkout_time'],
        'amenities': json['amenities'] == null ? undefined : ((json['amenities'] as Array<any>).map(HotelAmenityFromJSON)),
        'fullAmenities': json['full_amenities'] == null ? undefined : ((json['full_amenities'] as Array<any>).map(HotelAmenityFromJSON)),
        'images': json['images'] == null ? undefined : ((json['images'] as Array<any>).map(HotelImageFromJSON)),
        'externalReviews': json['external_reviews'] == null ? undefined : ((json['external_reviews'] as Array<any>).map(ExternalReviewFromJSON)),
        'hotelThemes': json['hotel_themes'] == null ? undefined : ((json['hotel_themes'] as Array<any>).map(HotelThemeFromJSON)),
        'rooms': json['rooms'] == null ? undefined : ((json['rooms'] as Array<any>).map(RoomInfoFromJSON)),
        'checkin': json['checkin'] == null ? undefined : (new Date(json['checkin'])),
        'checkout': json['checkout'] == null ? undefined : (new Date(json['checkout'])),
        'checkinStr': json['checkin_str'] == null ? undefined : json['checkin_str'],
        'checkoutStr': json['checkout_str'] == null ? undefined : json['checkout_str'],
        'nights': json['nights'] == null ? undefined : json['nights'],
        'lowestRate': json['lowest_rate'] == null ? undefined : json['lowest_rate'],
        'lowestRateCurrency': json['lowest_rate_currency'] == null ? undefined : json['lowest_rate_currency'],
        'nightlyRate': json['nightly_rate'] == null ? undefined : json['nightly_rate'],
        'lowestPoints': json['lowest_points'] == null ? undefined : json['lowest_points'],
        'lowestNightlyPoints': json['lowest_nightly_points'] == null ? undefined : json['lowest_nightly_points'],
        'lowestPointsAmount': json['lowest_points_amount'] == null ? undefined : PointsAmountFromJSON(json['lowest_points_amount']),
        'proximity': json['proximity'] == null ? undefined : json['proximity'],
        'pointsPercentile': json['points_percentile'] == null ? undefined : json['points_percentile'],
        'pointRedemptionValue': json['point_redemption_value'] == null ? undefined : json['point_redemption_value'],
        'cashPercentile': json['cash_percentile'] == null ? undefined : json['cash_percentile'],
        'cashDealScore': json['cash_deal_score'] == null ? undefined : json['cash_deal_score'],
        'pointsDealScore': json['points_deal_score'] == null ? undefined : json['points_deal_score'],
        'nightsFree': json['nights_free'] == null ? undefined : json['nights_free'],
        'transferPartnerDeals': json['transfer_partner_deals'] == null ? undefined : ((json['transfer_partner_deals'] as Array<any>).map(TransferPartnerSummaryFromJSON)),
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'userLoyaltyProgramStatus': json['user_loyalty_program_status'] == null ? undefined : json['user_loyalty_program_status'],
        'userLoyaltyPoints': json['user_loyalty_points'] == null ? undefined : json['user_loyalty_points'],
        'programTierIndex': json['program_tier_index'] == null ? undefined : json['program_tier_index'],
        'gondolaRecommended': json['gondola_recommended'] == null ? undefined : json['gondola_recommended'],
        'earningsPoints': json['earnings_points'] == null ? undefined : PointsAmountFromJSON(json['earnings_points']),
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
    };
}

export function HotelSummaryToJSON(value?: HotelSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recommendation_summary': RecommendationSummaryToJSON(value['recommendationSummary']),
        'vervotech_property_id': value['vervotechPropertyId'],
        'provider_property_id': value['providerPropertyId'],
        'property_name': value['propertyName'],
        'property_address': value['propertyAddress'],
        'city_name': value['cityName'],
        'country_code': value['countryCode'],
        'chain_name': value['chainName'],
        'brand_name': value['brandName'],
        'supplier_id': value['supplierId'],
        'coordinates': CoordinatesToJSON(value['coordinates']),
        'description': value['description'],
        'year_built': value['yearBuilt'],
        'year_last_renovated': value['yearLastRenovated'],
        'stars': value['stars'],
        'average_guest_rating': value['averageGuestRating'],
        'review_count': value['reviewCount'],
        'category_ratings': value['categoryRatings'] == null ? undefined : ((value['categoryRatings'] as Array<any>).map(RatingForCategoryToJSON)),
        'hero_image': value['heroImage'],
        'loyalty_program': value['loyaltyProgram'],
        'checkin_time': value['checkinTime'],
        'checkout_time': value['checkoutTime'],
        'amenities': value['amenities'] == null ? undefined : ((value['amenities'] as Array<any>).map(HotelAmenityToJSON)),
        'full_amenities': value['fullAmenities'] == null ? undefined : ((value['fullAmenities'] as Array<any>).map(HotelAmenityToJSON)),
        'images': value['images'] == null ? undefined : ((value['images'] as Array<any>).map(HotelImageToJSON)),
        'external_reviews': value['externalReviews'] == null ? undefined : ((value['externalReviews'] as Array<any>).map(ExternalReviewToJSON)),
        'hotel_themes': value['hotelThemes'] == null ? undefined : ((value['hotelThemes'] as Array<any>).map(HotelThemeToJSON)),
        'rooms': value['rooms'] == null ? undefined : ((value['rooms'] as Array<any>).map(RoomInfoToJSON)),
        'checkin': value['checkin'] == null ? undefined : ((value['checkin']).toISOString().substring(0,10)),
        'checkout': value['checkout'] == null ? undefined : ((value['checkout']).toISOString().substring(0,10)),
        'checkin_str': value['checkinStr'],
        'checkout_str': value['checkoutStr'],
        'nights': value['nights'],
        'lowest_rate': value['lowestRate'],
        'lowest_rate_currency': value['lowestRateCurrency'],
        'nightly_rate': value['nightlyRate'],
        'lowest_points': value['lowestPoints'],
        'lowest_nightly_points': value['lowestNightlyPoints'],
        'lowest_points_amount': PointsAmountToJSON(value['lowestPointsAmount']),
        'proximity': value['proximity'],
        'points_percentile': value['pointsPercentile'],
        'point_redemption_value': value['pointRedemptionValue'],
        'cash_percentile': value['cashPercentile'],
        'cash_deal_score': value['cashDealScore'],
        'points_deal_score': value['pointsDealScore'],
        'nights_free': value['nightsFree'],
        'transfer_partner_deals': value['transferPartnerDeals'] == null ? undefined : ((value['transferPartnerDeals'] as Array<any>).map(TransferPartnerSummaryToJSON)),
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagToJSON)),
        'user_loyalty_program_status': value['userLoyaltyProgramStatus'],
        'user_loyalty_points': value['userLoyaltyPoints'],
        'program_tier_index': value['programTierIndex'],
        'gondola_recommended': value['gondolaRecommended'],
        'earnings_points': PointsAmountToJSON(value['earningsPoints']),
        'status_nights': value['statusNights'],
    };
}

